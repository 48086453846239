import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'
import { Paper } from '../../components/Paper'
import './style.css'

export default function FeaturedPost() {
  const data = useStaticQuery(
    graphql`
      query getLatestFeaturedPost {
        allMarkdownRemark(
          filter: { frontmatter: { featuredpost: { eq: true } } }
          limit: 1
          sort: { fields: frontmatter___date, order: DESC }
        ) {
          nodes {
            id
            html
            frontmatter {
              featuredpost
              description
              featuredimage
              tags
              path
              title
              date(formatString: "MMMM DD, YYYY")
            }
          }
        }
      }
    `
  )
  console.log('featured posts')
  console.log(data)
  const {
    allMarkdownRemark: { nodes },
  } = data

  const {
    frontmatter: { date, description, featuredimage, path, title },
  } = nodes[0]

  return (
    <>
      <Paper>
        <h1>{title}</h1>
        <div class="banner">
          <img class="img" alt="Featured Post" src={featuredimage} />
        </div>
        <h2>{description}</h2>
        <Link className="button" to={path}>
          Keep Reading →
        </Link>
        <div class="footer">
          <h3 class="date">{date}</h3>
        </div>
      </Paper>
    </>
  )
}
