import React from 'react'
import Logo from '../assets/Logo'
import { Banner } from '../components/Banner'
import { BlogRoll } from '../components/BlogRoll'
import { FeaturedPost } from '../components/FeaturedPost'
import { StickySocialBar } from '../components/StickySocialBar'
import '../globalStyle.css'
import './style.css'

export default () => {
  return (
    <>
      <header class="header">
        <section class="headerSection">
          <div class="headerBanner">
            <div class="logoContainer">
              <a href="https://tiaandandy.com">
                <Logo class="logo" />
              </a>
            </div>
          </div>
        </section>
        <StickySocialBar />
      </header>
      <section class="bannerSection">
        <Banner />
      </section>
      <main class="blogSection">
        <FeaturedPost />
        <div class="blogDiv">
          <div>
            <BlogRoll />
          </div>
        </div>
      </main>
    </>
  )
}
