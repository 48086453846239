import React from 'react'
import './style.css'
import PropTypes from 'prop-types'

function StickySocialBar(props) {
  return (
    <div class={`${props.class}`}>
      <div class="icon-bar">
        <a href="https://youtube.com/c/tiaandandy" class="youtube">
          <i class="fa fa-youtube"></i>
        </a>
        <a href="https://www.instagram.com/tiaandyvacationdays/" class="insta">
          <i class="fa fa-instagram"></i>
        </a>
        <a href="https://www.facebook.com/tiaandyvacationdays" class="facebook">
          <i class="fa fa-facebook"></i>
        </a>
        <a href="https://twitter.com/tavacationdays" class="twitter">
          <i class="fa fa-twitter"></i>
        </a>
      </div>
    </div>
  )
}

StickySocialBar.propTypes = {
  class: PropTypes.string,
}

export default StickySocialBar
