import React from 'react'
import PropTypes from 'prop-types'
import './style.css'

function Paper(props) {
  return <div className={'paper ' + props.className}>{props.children}</div>
}

Paper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

export default Paper
