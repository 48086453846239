import React from 'react'
import { graphql } from 'gatsby'
import { StickySocialBar } from '../../components/StickySocialBar'
import { Seo } from '../../components/Seo'
import Logo from '../../assets/Logo'
import './style.css'

export default function Post({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const {
    markdownRemark: { frontmatter, html },
  } = data // data.markdownRemark holds your post data
  return (
    <>
      <Seo
        title={frontmatter.title}
        description={frontmatter.description || frontmatter.excerpt}
        image={frontmatter.image}
        path={frontmatter.path}
        keywords={frontmatter.tags}
      />
      <header class="header">
        <main class="headerSection">
          <div class="headerBanner">
            <div class="logoContainer">
              <a class="logoLink" href="https://tiaandandy.com">
                <Logo className="logo" />
              </a>
            </div>
          </div>
        </main>
        <StickySocialBar />
      </header>
      <main class="main">
        <h1>{frontmatter.title}</h1>
        <h2>{frontmatter.date}</h2>
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </main>
    </>
  )
}
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      id
      excerpt(pruneLength: 160)

      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        tags
      }
    }
  }
`
