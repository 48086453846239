import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'

function Banner() {
  const data = useStaticQuery(
    graphql`
      query getBanner {
        file(relativePath: { eq: "title_banner.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return (
    <div class="landing">
      <div class="homeWrap">
        <Img
          fluid={data.file.childImageSharp.fluid}
          alt="passion led us here"
        />
      </div>
    </div>
  )
}
export default Banner
