import { graphql, Link, StaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { Paper } from '../Paper'
import './style.css'

function BlogRoll({ data }) {
  const { edges: posts } = data.allMarkdownRemark
  return (
    <div className="grid">
      {posts &&
        posts.map(({ node: post }) => (
          <Paper className="paper" key={post.id}>
            <article>
              <header>
                <p>
                  <Link className="" to={post.frontmatter.path}>
                    {post.frontmatter.title}
                  </Link>
                  <span> &bull; </span>
                  <span>{post.frontmatter.date}</span>
                </p>
              </header>
              {post.frontmatter.featuredimage && (
                <section>
                  <img
                    class="img"
                    alt="Featured Post"
                    src={post.frontmatter.featuredimage}
                  />
                </section>
              )}
              <p>
                <br />
                {post.excerpt}
                <br />
                <br />
              </p>
            </article>
            <Link className="button contbtn" to={post.frontmatter.path}>
              Keep Reading →
            </Link>
          </Paper>
        ))}
    </div>
  )
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              frontmatter {
                path
                title
                featuredimage
                date(formatString: "MMMM DD, YYYY")
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)
