import * as React from 'react'

function Logo(props) {
  return (
    <svg data-name="Layer 1" viewBox="0 0 124 151.93" {...props}>
      <title>{'favicon'}</title>
      <path
        fill="#fff"
        d="M8.41 42.93l53.5-32.62 53.68 32.77v65.91L62.1 141.62 8.42 108.96l-.01-66.03z"
      />
      <path
        d="M8.41 75.89v-33L35 26.64c14.62-9 26.73-16.31 26.9-16.33s7.27 4.22 27 16.31L115.58 43l8.42-5.21-30.87-18.92C70.3 4.88 62.16 0 61.89 0S47.68 8.53 30.76 18.89L0 37.74V114l8.41-5.17z"
        fill="#828282"
      />
      <path d="M115.59 76v33L89 125.29c-14.62 9-26.73 16.31-26.9 16.33s-7.27-4.22-27-16.31L8.42 109 0 114.14l30.87 18.91c22.84 14 31 18.91 31.24 18.88S76.32 143.4 93.24 133L124 114.18V37.9l-8.41 5.17z" />
      <path d="M34.31 66.14s-4.87.62-5.58.62h-.29c-.91 0-2.68-.34-2.68-1.68 0-.51.81-1 1-1.68.32-1.06 3-2.57 4-2.67 1.38-.1 2.77-.14 4.16-.2l2.9-.14a60.91 60.91 0 014.43-6.34c.38-.55.45-.68 1.13-.68.51 0 1-.21 1.51-.21.81 0 .81.34 1.33.34s.74.55.74.93c0 .55-2.78 5-3.26 5.82.42 0 2.42-.2 3.19-.2a7.39 7.39 0 011.78.13c.77.35 1 1.27 1.71 1.82a1.15 1.15 0 01.62 1c0 1.37-1.62 1.92-2.58 2-3 .31-5.3.65-8.27.82-2.32 3.82-11.84 21.08-12.29 24.5a10.7 10.7 0 00-.13 1.34c0 1.34.55 1.68 1.58 1.68 3.1 0 8.71-6.85 9.52-6.85s.84 2.46.84 3.08a3.72 3.72 0 01-1.45 3c-2.17 2-6.23 6-9.94 6a3.79 3.79 0 01-.91-.1c-3.29-.65-5-2.26-5-6.68.06-5.37 9.04-20.89 11.94-25.65z" />
      <path d="M64.29 92.59c-2.35 2.29-6.06 5.89-9.9 5.89A5.62 5.62 0 0149 92.93c0-4.35 3.52-10.14 6.23-14.39a15 15 0 001.71-3.22l-.06-.07c-1.68.14-10.43 9.9-12 11.79-2.42 2.84-4.61 5.82-6.74 8.83-.46.62-1.26 1.48-2 1.48a9.19 9.19 0 01-3-1.2.55.55 0 01-.22-.55c0-1.13 1.22-2.95 1.93-4.21 2.78-5.14 5.62-10.18 8.46-15.21a44.1 44.1 0 012.84-4.86c1.1-1.48 3.19-2 4.9-2 .71 0 .78.76.78 1.34 0 1.61-3.23 6.3-4.16 7.91 3.09-3.63 8.06-9.18 13-9.18a3 3 0 013 3.08c0 2.09-3.91 7.64-5.17 9.7-1.08 1.83-4.7 7.47-4.7 9.83 0 1.1 1 1.51 1.94 1.51 3.07 0 8.3-7 9.23-7s.84 2.46.84 3.08a3.37 3.37 0 01-1.52 3z" />
      <path d="M92.63 89.57a3.86 3.86 0 01-.19 1.43c-.87 1.82-5.81 7.44-10.14 7.44-3.35 0-4.81-2.85-4.81-5.72a8.5 8.5 0 01.65-3.26c-3.55 4.9-8.78 8.84-12 8.84a5.22 5.22 0 01-5-4.69 10 10 0 01-.14-1.68c0-10.17 12.13-21 19.3-21 2.48 0 3.87 1 4.23 2.16 1.06-1.75 1.58-1.88 2.9-1.88s2.45.54 2.45 1.78a2.31 2.31 0 01-.45 1.44c-.48.54-5.87 9.89-6.94 16.06a4.22 4.22 0 00-.09 1c0 .86.22 1.88 1 1.88 2.58 0 7.13-6.06 7.65-6.54a1.1 1.1 0 01.71-.31.7.7 0 01.68.65 19.54 19.54 0 01.19 2.4zm-20.95-8c-2.84 3.11-5.61 8.32-5.61 10.37 0 .55.19.86.64.86 1.23 0 8.59-6.82 11-10 1.45-2 3.65-5.28 5.36-7.71-2.16-.04-6.38.91-11.39 6.47z" />
      <path
        d="M99.8 93.92c0 2.43-2.3 5.52-4.94 5.52a2.18 2.18 0 01-2.33-2.5 5.47 5.47 0 015-5.35 2.12 2.12 0 012.27 2.33z"
        fill="red"
      />
    </svg>
  )
}

export default Logo
